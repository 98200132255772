<template>
  <BaseTooltip :tip="parseFullDate(value, showTime)" small>
    <template v-slot:content>
      {{ parseDate(value, showTime) }}
    </template>
  </BaseTooltip>
</template>

<script lang="ts" setup>

const {locale} = useI18n()

defineProps<{
  value?: string | number
  showTime?: boolean
}>()

const longDateObject: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
}

const fullDateObject: Intl.DateTimeFormatOptions = {
  ...longDateObject,
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
}

const parseDate = (value: string, showTime: boolean) => {
  if (value) {
    const date = new Date(value)
    const now = new Date()
    const isCurrentYear = date.getFullYear() === now.getFullYear()

    if (date.toLocaleString() !== 'Invalid Date') {
      let options: Intl.DateTimeFormatOptions
      if (showTime) {
        options = {
          day: 'numeric',
          month: 'short',
          hour: 'numeric',
          minute: 'numeric',
          ...(isCurrentYear ? {} : { year: 'numeric' }),
        }
      } else {
        options = {
          day: 'numeric',
          month: 'short',
          ...(isCurrentYear ? {} : { year: 'numeric' }),
        }
      }
      return date.toLocaleString(locale.value, options)
    } else {
      return 'Invalid Date'
    }
  } else {
    return ''
  }
}

const parseFullDate = (value: string, showTime: boolean) => {
  const date = new Date(value)

  if (date.toLocaleString() !== 'Invalid Date') {
    if (showTime) {
      return date.toLocaleString(locale.value, fullDateObject)
    } else {
      return date.toLocaleString(locale.value, longDateObject)
    }
  } else {
    return 'Invalid Date'
  }
}
</script>

<style></style>
